<template>
	<div>

		<div class="card" v-if="files.length > 0">
			<div class="card-body">
				<div class="list-group list-group-flush">
					<FileItem v-for="(file, index) in this.files" :key="file.id" :file="file" @cancel="cancelFile(index)"></FileItem>
				</div>
			</div>
		</div>

		<div class="d-flex w-100 align-items-center upload-container" :style="{
			margin: (files.length == 0 ? '15vh 0 3rem 0' : '3rem 0')
		}">
			<div class="text-center mx-auto">
				<button class="btn btn-primary new-file" @click="newFile()" :disabled="!built">
					<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 40 40" enable-background="new 0 0 40 40" xml:space="preserve">
						<g>
							<g>
								<g>
									<path d="M26,20.5H14c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5h12c0.3,0,0.5,0.2,0.5,0.5S26.3,20.5,26,20.5z"/>
								</g>
								<g>
									<path d="M20,26.5c-0.3,0-0.5-0.2-0.5-0.5V14c0-0.3,0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5v12 C20.5,26.3,20.3,26.5,20,26.5z"/>
								</g>
							</g>
						</g>
					</svg>
				</button>
				<h4 v-if="files.length == 0">Carica i file</h4>
				<p class="text-secondary m-0" v-if="files.length == 0">Aggiungi fino a {{ filesize }} MB</p>
			</div>
		</div>
		<div id="new-form" v-html="fileForm" style="display:none"></div>
		
		<form v-if="files.length > 0" v-on:submit.prevent="submit" class="mb-4">

			<div class="card mb-5" v-if="request.totale">
				<div class="card-body">
					<label for="totale" class="form-label">Importo totale da corrispondere</label>
					<input class="form-control" type="number" min="0" step="0.01" autocomplete="off" name="totale" id="totale" placeholder="Euro" required="required">
					<div class="text-secondary small">(incl. IVA e previdenza e al netto della ritenuta d'acconto)</div>
				</div>
			</div>

			<div class="card mb-5">
				<div class="card-body">
					<label for="password" class="form-label">Codice di conferma</label>
					<input class="form-control" type="text" autocomplete="off" name="password" id="password" v-model="password">
				</div>
			</div>

			<div class="row">
				<div class="col-6">
					<div class="d-grid">
						<button type="reset" class="btn btn-primary-transparent" @click="reset">Ricomincia</button>
					</div>
				</div>
				<div class="col-6">
					<div class="d-grid">
						<button type="submit" class="btn btn-primary" :disabled="password.length < 1">Conferma</button>
					</div>
				</div>
			</div>

		</form>

		<div class="alert alert-danger" v-if="alert.length > 0">
			<b class="me-2">Errore</b>{{ alert }}
		</div>

		<Loading :show="loading"></Loading>
	</div>
</template>

<script>
import axios from "axios";
import FileItem from '@/components/FileItem.vue'
import Loading from '@/components/Loading.vue'

export default {
	name: 'FileForm',
	props: ['request'],
	emits: ['success'],
	components: {
		FileItem,
		Loading
	},
	data() {
		return {
			// built è true quando viene acquisito il form Notes
			built: false,
			// è il Form creato da Notes per il modulo di upload
			fileForm: '',
			// endpoint del modulo Notes
			action: '',
			// file inseriti dall'utente
			files: [],
			// inseriti dall'utente
			password: '',
			// errori/avvisi all'utente
			alert: '',
			// mostra 'attendere'
			loading: false,
			// solo per UI
			filesize: process.env.VUE_APP_FILESIZE
		}
	},
	methods: {
		newFile() {
			const input = document.querySelector('#new-form input[type=file]');
			input.removeEventListener('change', this.appendfile);
			input.addEventListener('change', this.appendFile);
			input.click();
		},
		appendFile(event) {
			if (event.target.files.length > 0) {
				let data = new FormData(event.target.form);
				this.files.push({
					id: new Date().toISOString().replace(/[-:.Z]/g, '') + event.target.files[0].size,
					name: event.target.files[0].name,
					size: event.target.files[0].size,
					data: data
				});
			}
		},
		cancelFile(index) {
			(this.files.length == 1 ? this.reset() : this.files.splice(index, 1))
		},
		reset() {
			this.files = [];
			this.alert = '';
			this.password = '';
		},
		submit(e) {
			if (this.password.length < 1) return;
			this.loading = true;
			this.alert = '';

			let params = new URLSearchParams();
			for (let n = 0; n < e.target.elements.length; ++n) {
				const elem = e.target.elements[n];
				if ((elem.name || '').length > 0) params.append(elem.name, elem.value);
			}
			params.append('clock', new Date().getTime().toString(16));
			params.append('token', this.request.token);
			axios.request({
				method: 'post',
				url: process.env.VUE_APP_ENDPOINT + process.env.VUE_APP_WEBDBNAME + 'upload-auth',
				data: params,
				headers: { 'Content-Type': 'text/plain' }
			}).then(res => {
				if (res.status == 200) {
					if (res.data.success) {
						this.alert = '';
						let requests = {
							fired: 0,
							completed: 0,
							failed: 0,
							that: this,
							quit: function(err) {
								if (this.completed + this.failed == this.fired) {
									this.that.loading = false;
									if (this.failed == 1 && this.fired == 1) {
										this.that.alert = 'Impossibile caricare il file' + (err ? ': ' + err : ' (errore sconosciuto)');
									} else if (this.failed > 0) {
										this.that.alert = 'Uno o più file non sono stati caricati. Si prega di riprovare.';
									} else {
										axios.request({
											method: 'post',
											url: process.env.VUE_APP_ENDPOINT + process.env.VUE_APP_WEBDBNAME + 'upload-confirm',
											data: params,
											headers: { 'Content-Type': 'text/plain' }
										}).then(res => {
											this.that.loading = false;
											if (res.status == 200) {
												if (res.data.success) {
													this.that.alert = '';
													this.that.$emit('success');
												} else {
													this.that.alert = res.data.message || 'Errore server';
												}
											} else {
												this.alert = 'Il servizio di conferma non è disponibile (status ' + res.status + ')';
											}
										}).catch(err => {
											this.alert = 'Il servizio di conferma è terminato in modo imprevisto (' + err + ')';
											console.error(err);
											this.loading = false;
										});
									}
								}
							}
						};
						this.files.forEach((file) => {
							file.data.set("Token", params.get('token'));
							file.data.set("Clock", params.get('clock'));
							axios.request({
								method: 'post',
								url: process.env.VUE_APP_ENDPOINT + this.action,
								data: file.data
							}).then(res => {
								(res.status == 200 ? requests.completed++ : requests.failed++);
								requests.quit(false);
							}).catch(err => {
								requests.failed++;
								console.error(err);
								requests.quit(err);
							});
							requests.fired++;
						});
					} else {
						this.alert = res.data.message || 'Errore server';
						this.loading = false;
					}
					
				} else {
					this.alert = 'Il servizio di autorizzazione non è disponibile (status ' + res.status + ')';
				}
			}).catch(err => {
				this.alert = 'Il servizio di autorizzazione è terminato in modo imprevisto (' + err + ')';
				console.error(err);
				this.loading = false;
			});
		}
	},
	mounted() {
		axios.request({
			method: 'post',
			url: process.env.VUE_APP_ENDPOINT + process.env.VUE_APP_WEBDBNAME + process.env.VUE_APP_FORM
		}).then(res => {
			if (res.status == 200) {
				let virtual = document.createElement('virtual');
				virtual.innerHTML = res.data;
				let elem = virtual.getElementsByTagName('form')[0];
				this.action = elem.getAttribute('action');
				this.fileForm = elem.outerHTML;
				this.built = true;
			} else {
				this.alert = 'Impossibile caricare il modulo esterno (status ' + res.status + ')'
			}
		}).catch(err => {
			this.alert = 'Il caricamento del modulo esterno è terminato in modo imprevisto (' + err + ')';
		});
	},
	__alert: '',
	updated() {
		if (this.alert != this.$options.__alert) {
			this.$options.__alert = this.alert;
			if (this.alert.length > 0) window.scrollTo(0, document.body.scrollHeight);
		}
	}
}
</script>

<style>
.upload-container h4 {
	margin-top:2rem;
	margin-bottom:0.25rem;
}
.new-file {
	border-radius:50% !important;
	padding:0rem !important;
}
.new-file svg {
	width:5rem;
	fill:white;
}

.btn-master {
	font-weight:600;
	padding:1rem;
}
.loading {
	position:fixed;
	top:0;
	left:0;
	right:0;
	bottom:0;
	background: rgba(0,0,0,0.25);
	z-index:1000;
}
</style>