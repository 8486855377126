<template>
	<div class="list-group-item">
		<div class="d-flex align-items-center">
			<div class="file-info">
				<h4 class="mb-1" @click="submit()">{{ file.name }}</h4>
				<div :class="sizeClass">{{ sh }}</div>
			</div>
			<div class="ms-auto">
				<button type="button" class="file-cancel" @click="cancel()">
					<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 40 40" enable-background="new 0 0 40 40" xml:space="preserve">
						<g>
							<g>
								<g>
									<path d="M20,28.5c-4.7,0-8.5-3.8-8.5-8.5s3.8-8.5,8.5-8.5s8.5,3.8,8.5,8.5S24.7,28.5,20,28.5z M20,12.5
										c-4.1,0-7.5,3.4-7.5,7.5s3.4,7.5,7.5,7.5s7.5-3.4,7.5-7.5S24.1,12.5,20,12.5z"/>
								</g>
								<g>
									<path d="M22.1,22.6c-0.1,0-0.3,0-0.4-0.1l-4.2-4.2c-0.2-0.2-0.2-0.5,0-0.7s0.5-0.2,0.7,0l4.2,4.2
										c0.2,0.2,0.2,0.5,0,0.7C22.3,22.5,22.2,22.6,22.1,22.6z"/>
								</g>
								<g>
									<path d="M17.9,22.6c-0.1,0-0.3,0-0.4-0.1c-0.2-0.2-0.2-0.5,0-0.7l4.2-4.2c0.2-0.2,0.5-0.2,0.7,0s0.2,0.5,0,0.7
										l-4.2,4.2C18.2,22.5,18,22.6,17.9,22.6z"/>
								</g>
							</g>
						</g>
					</svg>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'FileItem',
	props: ['file'],
	emits: ['cancel'],
	computed: {
		sh() {
			let um = 'B';
			let size = this.$props.file.size;
			if (size > 1000) {
				size /= 1024;
				um = 'KB';
				if (size > 1000) {
					size /= 1024;
					um = 'MB';
				}
			}
			size = (size < 1 ? 1 : Math.floor(size));
			return size + ' ' + um;
		},
		sizeClass() {
			const mb = this.$props.file.size / (1024 * 1024);
			return (mb > parseFloat(process.env.VUE_APP_FILESIZE) ? 'text-danger' : 'text-secondary');
		}
	},
	methods: {
		cancel() {
			this.$emit('cancel');
		},
		submit() {
			this.$props.file.form.getElementsByTagName('form')[0].submit();
		}
	}
}
</script>

<style>
.file-info>* {
	font-size:inherit;
}
.file-cancel {
	border:0;
	background:transparent;
	padding:0;
}
.file-cancel svg {
	width:5rem;
	fill:#DDD;
}
.file-cancel:hover svg {
	fill:#999;
}
</style>