<template>
	<div>
		<nav class="navbar navbar-dark bg-dark">
			<div class="container-fluid">
				<span class="navbar-brand">Boscolo</span>
			</div>
		</nav>

		<template v-if="!loading">
		
			<main v-if="request.token.length != 8" class="container-xl">
				<div class="alert alert-danger">
					<b class="me-2">Errore</b>{{ alert }}
				</div>
			</main>
		
			<template v-if="request.token.length == 8">

				<main class="container-xl">
					<h6 class="text-center mb-5" v-if="request.subject.length > 0">{{ request.subject }}</h6>

					<FileForm v-if="!success" :request="request" @success="success = true"></FileForm>

					<div class="alert alert-success" v-if="success">
						<b class="me-3">Grazie!</b>Caricamento completato.
					</div>
				</main>

			</template>

		</template>

		<Loading :show="loading"></Loading>
					
	</div>
</template>

<script>
import axios from "axios";
import FileForm from './components/FileForm.vue'
import Loading from '@/components/Loading.vue'

export default {
	name: 'App',
	components: {
		FileForm,
		Loading
	},
	data() {
		return {
			success: false,
			loading: true,
			alert: '',
			// prelevato da agente "init"
			request: {
				subject: '',
				token: ''
			}
		}
	},
	created() {
		let url = document.location.pathname;
		// se finisce con '/' rimuove l'ultimo carattere
		if (url.endsWith('/')) url = url.substring(0, url.length - 1);
		// assegna token
		if (url.indexOf('/') >= 0) {
			let params = new URLSearchParams();
			params.append('token', url.substring(1 + url.lastIndexOf('/')));
			axios.request({
				method: 'post',
				url: process.env.VUE_APP_ENDPOINT + process.env.VUE_APP_WEBDBNAME + 'upload-init',
				data: params,
				headers: { 'Content-Type': 'text/plain' }
			}).then(res => {
				this.loading = false;
				if (res.status == 200) {
					if (res.data.success) {
						this.request = res.data;
					} else {
						this.alert = res.data.message || 'Errore server';
					}
				} else {
					this.alert = 'Il servizio di inizializazione non è disponibile (status ' + res.status + ')';
				}
			}).catch(err => {
				this.loading = false;
				this.alert = "Inizializzazione dell'applcazione: " + err;
			});
		} else {
			this.loading = false;
			this.alert = 'URL non valido';
		}
	}
}
</script>

<style>
main {
	margin:2rem 0;
}
</style>
