<template>
	<div class="loading" v-if="show">
		<div class="d-flex align-items-center h-100">
			<div class="card mx-auto">
				<div class="card-body pe-4">
					<div class="d-flex h-100 align-items-center">
						<div class="spinner-border text-primary"></div>
						<div class="ms-3">Attendere...</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Loading',
	props: ['show']
}
</script>
